import React, { useState, useEffect, useCallback } from 'react';
import { ReactP5Wrapper } from 'react-p5-wrapper';
import { getFees } from './apiService';  // Ensure this path is correct based on your project structure
import _ from 'lodash';
import './viewdnft.css'

// Define the global variable for mobile detection
const isMobile = window.matchMedia('(max-width: 600px)').matches;

function ViewdNFTComponent() {
    const [settings, setSettings] = useState({
        heartSize: 4,
        colors: ['red', 'green', '#ccc'],
        rateRange: [0.5, 0.8],
        flickerChance: 0.5
    });  // Initialized with default values
    const [fees, setFees] = useState(null); // State to hold fee data
    const [totalGrids, setTotalGrids] = useState(0); // State to hold the number of grids
    const [totalBeats, setTotalBeats] = useState(0); // State to hold the number of beats

    const [canvasSize, setCanvasSize] = useState({
        width: isMobile ? 600 : 601,
        height: 400
    });
    const redColors = ['#F7D7DC', '#FC7676', '#d9381e']; // lighter to darker red
    const greenColors = ['#ccffcc', 'Green', '#18bd22']; // lighter to darker green
    const ColorBlock = ({ color }) => {
        const style = {
            width: '20px',
            height: '10px',
            display: 'inline-block',
            margin: '5px',
            backgroundColor: color,
        };

        return <div style={style}></div>;
    };

    const [lastUpdatedTime, setLastUpdatedTime] = useState(new Date()); // State to hold the last updated time
    const [sliderInterval, setSliderInterval] = useState(isMobile ? 3 : 1);
    const [timeline, setTimeline] = useState(30);

    const handleSliderChange = (event) => {
        const newTimeline = event.target.value;
        setTimeline(newTimeline);
        debounceFetchFees(newTimeline * 24); // Convert days to hours
    };

    // Debounce function to call fetchFees after user stops sliding
    const debounceFetchFees = useCallback(
        _.debounce((hours) => {
            fetchFees(hours);
        }, 500), // 500ms delay
        []
    );

    const fetchFees = (hours) => {
        getFees(hours)
            .then(data => {
                setFees(data);
                if (data.length > 0) {
                    const maxDate = new Date(Math.max(...data.map(fee => new Date(fee.date).getTime())));
                    setLastUpdatedTime(maxDate); // Update the last updated time state with the max date
                }
                // Calculate gridSize based on number of records and canvas size
                const totalCells = data.length;
                const gridSize = Math.sqrt((canvasSize.width * canvasSize.height) / totalCells);
                const gridsPerRow = Math.floor(canvasSize.width / gridSize);
                const gridsPerColumn = Math.floor(canvasSize.height / gridSize);
                const totalGrids = gridsPerRow * gridsPerColumn;

                setTotalGrids(totalGrids); // Set the number of grids
                setTotalBeats(data.length); // Set the number of beats

                setSettings(prevSettings => ({
                    ...prevSettings,
                    gridSize: Math.floor(gridSize)
                }));
            })
            .catch(error => {
                console.error('Error fetching fees:', error);
            });
    };

    useEffect(() => {
        fetchFees(timeline * 24); // Fetch initial fees for the default timeline
    }, [timeline]);

    const sketch = (p) => {
        let hearts = [];
        p.setup = function () {
            p.createCanvas(canvasSize.width, canvasSize.height - 15);
            if (!settings.gridSize || !fees) return; // Avoid setup until settings and fees are loaded

            let feeIndex = 0;
            for (let x = 5; x < p.width && feeIndex < fees.length; x += settings.gridSize) {
                for (let y = 5; y < p.height && feeIndex < fees.length; y += settings.gridSize) {
                    let heartColor = getHeartColor(feeIndex); // Use the function to determine color based on fees
                    let rate = p.random(...settings.rateRange);
                    let phase = p.random(p.TWO_PI);
                    let flicker = p.random() < settings.flickerChance;

                    hearts.push({ x, y, heartColor, rate, phase, flicker, visible: true });
                    feeIndex++;
                }
            }
        };

        p.draw = function () {
            if (!settings.gridSize || !fees) return; // Avoid draw until settings and fees are loaded

            p.background("#111");
            hearts.forEach(heart => {
                if (heart.flicker && p.random() < 0.01) {
                    heart.visible = !heart.visible;
                }

                if (heart.visible) {
                    let sizeFactor = heart.flicker ? p.sin(p.frameCount * heart.rate + heart.phase) * 0.05 + 1 : 1;
                    drawHeart(heart.x, heart.y, settings.heartSize * sizeFactor, heart.heartColor);
                }
            });
        };

        function getColorFromVariation(variation, thresholds, colors) {
            let percentageVariation = Math.abs((variation - 1) * 100);
            for (let i = 0; i < thresholds.length; i++) {
                if (percentageVariation < thresholds[i]) {
                    return colors[i];
                }
            }
            return colors[colors.length - 1];
        }

        function getHeartColor(index) {
            let fee = fees[index];
            let thresholds = [5, 15, 25];


            let btcColor = getColorFromVariation(fee.BTCVariation, thresholds, fee.BTCVariation < 1 ? redColors : greenColors);
            let ethColor = getColorFromVariation(fee.ETHVariation, thresholds, fee.ETHVariation < 1 ? redColors : greenColors);

            let heartColor;
            if (redColors.includes(btcColor) && greenColors.includes(ethColor)) {
                heartColor = btcColor;
            } else if (greenColors.includes(btcColor) && redColors.includes(ethColor)) {
                heartColor = ethColor;
            } else if (redColors.includes(btcColor) && redColors.includes(ethColor)) {
                heartColor = redColors[redColors.length - 1]; // darkest red
            } else {
                heartColor = Math.random() < 0.5 ? btcColor : ethColor;
            }

            return heartColor;
        }

        function drawHeart(x, y, size, col) {
            p.fill(col);
            p.stroke(col);
            p.strokeWeight(1);
            p.beginShape();
            p.vertex(x, y - size / 2);
            p.bezierVertex(x - size * 0.5, y - size * 1.5, x - size * 1.5, y + size / 3, x, y + size);
            p.bezierVertex(x + size * 1.5, y + size / 3, x + size * 0.5, y - size * 1.5, x, y - size / 2);
            p.endShape(p.CLOSE);
        }
    };

    return (
        <div class="viewer">
           
        <div class="artwork">
            <ReactP5Wrapper sketch={sketch} />
        </div>

        <div class="stats">
            
       Updated 1hr ago: {totalBeats} beats minted since the first seed.
          
        
     </div>
    </div>
);
}

export default ViewdNFTComponent;
