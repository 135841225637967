import React, { useState, useEffect, useCallback } from 'react';
import { ReactP5Wrapper } from 'react-p5-wrapper';
import { getFees } from './apiService';  // Ensure this path is correct based on your project structure
import _ from 'lodash';

// Define the global variable for mobile detection
const isMobile = window.matchMedia('(max-width: 600px)').matches;

function ViewHomeComponent() {
    const [settings, setSettings] = useState({
        heartSize: 4,
        colors: ['red', 'green', '#ccc'],
        rateRange: [0.5, 0.8],
        flickerChance: 0.5
    });  // Initialized with default values
    const [fees, setFees] = useState(null); // State to hold fee data
    const [totalGrids, setTotalGrids] = useState(0); // State to hold the number of grids
    const [totalBeats, setTotalBeats] = useState(0); // State to hold the number of beats

    const [canvasSize, setCanvasSize] = useState({
        width: isMobile ? 600 : 601,
        height: 400
    });
    const redColors = ['#F7D7DC', '#FC7676', '#d9381e']; // lighter to darker red
    const greenColors = ['#ccffcc', 'Green', '#18bd22']; // lighter to darker green
    const ColorBlock = ({ color }) => {
        const style = {
            width: '20px',
            height: '10px',
            display: 'inline-block',
            margin: '5px',
            backgroundColor: color,
        };

        return <div style={style}></div>;
    };

    const [lastUpdatedTime, setLastUpdatedTime] = useState(new Date()); // State to hold the last updated time
    const [sliderInterval, setSliderInterval] = useState(isMobile ? 3 : 1);
    const [timeline, setTimeline] = useState(30);

    const handleSliderChange = (event) => {
        const newTimeline = event.target.value;
        setTimeline(newTimeline);
        debounceFetchFees(newTimeline * 24); // Convert days to hours
    };

    // Debounce function to call fetchFees after user stops sliding
    const debounceFetchFees = useCallback(
        _.debounce((hours) => {
            fetchFees(hours);
        }, 500), // 500ms delay
        []
    );

    const fetchFees = (hours) => {
        getFees(hours)
            .then(data => {
                setFees(data);
                console.log('Fees fetched:', data); // Logging the fees to console
                if (data.length > 0) {
                    const maxDate = new Date(Math.max(...data.map(fee => new Date(fee.date).getTime())));
                    setLastUpdatedTime(maxDate); // Update the last updated time state with the max date
                }
                // Calculate gridSize based on number of records and canvas size
                const totalCells = data.length;
                const gridSize = Math.sqrt((canvasSize.width * canvasSize.height) / totalCells);
                const gridsPerRow = Math.floor(canvasSize.width / gridSize);
                const gridsPerColumn = Math.floor(canvasSize.height / gridSize);
                const totalGrids = gridsPerRow * gridsPerColumn;

                setTotalGrids(totalGrids); // Set the number of grids
                setTotalBeats(data.length); // Set the number of beats

                setSettings(prevSettings => ({
                    ...prevSettings,
                    gridSize: Math.floor(gridSize)
                }));
            })
            .catch(error => {
                console.error('Error fetching fees:', error);
            });
    };

    useEffect(() => {
        fetchFees(timeline * 24); // Fetch initial fees for the default timeline
    }, [timeline]);

    const sketch = (p) => {
        let hearts = [];
        p.setup = function () {
            p.createCanvas(canvasSize.width, canvasSize.height - 15);
            if (!settings.gridSize || !fees) return; // Avoid setup until settings and fees are loaded
            console.log("width: " + canvasSize.width);

            let feeIndex = 0;
            for (let x = 5; x < p.width && feeIndex < fees.length; x += settings.gridSize) {
                for (let y = 5; y < p.height && feeIndex < fees.length; y += settings.gridSize) {
                    let heartColor = getHeartColor(feeIndex); // Use the function to determine color based on fees
                    let rate = p.random(...settings.rateRange);
                    let phase = p.random(p.TWO_PI);
                    let flicker = p.random() < settings.flickerChance;

                    hearts.push({ x, y, heartColor, rate, phase, flicker, visible: true });
                    feeIndex++;
                }
            }
        };

        p.draw = function () {
            if (!settings.gridSize || !fees) return; // Avoid draw until settings and fees are loaded

            p.background("#111111");
            hearts.forEach(heart => {
                if (heart.flicker && p.random() < 0.01) {
                    heart.visible = !heart.visible;
                }

                if (heart.visible) {
                    let sizeFactor = heart.flicker ? p.sin(p.frameCount * heart.rate + heart.phase) * 0.05 + 1 : 1;
                    drawHeart(heart.x, heart.y, settings.heartSize * sizeFactor, heart.heartColor);
                }
            });
        };

        function getColorFromVariation(variation, thresholds, colors) {
            let percentageVariation = Math.abs((variation - 1) * 100);
            for (let i = 0; i < thresholds.length; i++) {
                if (percentageVariation < thresholds[i]) {
                    return colors[i];
                }
            }
            return colors[colors.length - 1];
        }

        function getHeartColor(index) {
            let fee = fees[index];
            let thresholds = [5, 15, 25];


            let btcColor = getColorFromVariation(fee.BTCVariation, thresholds, fee.BTCVariation < 1 ? redColors : greenColors);
            let ethColor = getColorFromVariation(fee.ETHVariation, thresholds, fee.ETHVariation < 1 ? redColors : greenColors);

            let heartColor;
            if (redColors.includes(btcColor) && greenColors.includes(ethColor)) {
                heartColor = btcColor;
            } else if (greenColors.includes(btcColor) && redColors.includes(ethColor)) {
                heartColor = ethColor;
            } else if (redColors.includes(btcColor) && redColors.includes(ethColor)) {
                heartColor = redColors[redColors.length - 1]; // darkest red
            } else {
                heartColor = Math.random() < 0.5 ? btcColor : ethColor;
            }

            return heartColor;
        }

        function drawHeart(x, y, size, col) {
            p.fill(col);
            p.stroke(col);
            p.strokeWeight(1);
            p.beginShape();
            p.vertex(x, y - size / 2);
            p.bezierVertex(x - size * 0.5, y - size * 1.5, x - size * 1.5, y + size / 3, x, y + size);
            p.bezierVertex(x + size * 1.5, y + size / 3, x + size * 0.5, y - size * 1.5, x, y - size / 2);
            p.endShape(p.CLOSE);
        }
    };

    return (
        <div className="App">
            <header className="App-header">
                <h1>the <span className="heartbeat">♡ </span> beat</h1>
                <h2>/ a canvas that grows every hour /</h2>
                <p>by <a class="prj" href="https://uncode.art" target="_blank">Afzal Ibrahim</a></p>
                <p>&nbsp;</p>
                <p>A growing meadow of heartbeats. </p>
                <p>&nbsp;</p>
                <p>The meadow reflects the heartbeat of a collective dream, colored in shades of passion and prudence. A greener meadow is growth and a profound emblem of hope towards a decentralized world. It renews our bond with the evolving ethos of economic value.  </p>
                <p>&nbsp;</p>
                <p>The artwork, dynamically minted (dNFT) on the blockchain, employs a minimal visual language that utilizes colors, symbols, and code.</p>

                <p>&nbsp;</p>
                <p>Over time, the grid becomes denser, the number of hearts multiplies, the colors diversify, and an abstract masterpiece emerges.</p>

            </header>

            <section className="App-image">

                <h6>Artwork: {totalBeats} beats in-view </h6>

                <div className="arts">
                    <ReactP5Wrapper sketch={sketch} />
                </div>
            </section>
            <section className="App-timeline-slider">
                <h3>Baseline slider <span className="help-icon">
                    ?
                    <div className="popup">The slider adjusts the baseline period to compare each heart with averages over the past x days.
                    </div>
                </span></h3>
                <input
                    type="range"
                    min="0"
                    max="30"
                    step={sliderInterval}
                    value={timeline}
                    onChange={handleSliderChange}
                    className="timeline-slider"
                    style={{ width: '100%' }}
                />
                <div className="ruler">
                    {Array.from({ length: Math.ceil(31 / sliderInterval) }, (_, index) => (
                        <div key={index} className="ruler-mark">{index * sliderInterval}</div>
                    ))}
                </div>
                <p>Variation baseline based on the last {timeline} {timeline === "1" ? "day" : "days"}</p>
                <p><a class="prj" href="https://ipfs.io/ipfs/Qmd4G9k9FUE9EHux9RnZuDrxaMSxPWpVxLZWBDLPGH8VSj" target="_blank">View the metadata in the public chain</a></p>
                <p>Refreshed on {lastUpdatedTime.toLocaleTimeString('en-US', { timeZone: 'Asia/Dubai', hour: '2-digit', minute: '2-digit', second: '2-digit' })} GST</p>

                <div class="table-container">
                    <table>

                        <tbody>
                            <tr><td><strong>Attributes</strong></td><td></td></tr>
                            <tr><td>Color</td><td>Neutral</td></tr>
                            <tr><td>Intensity</td><td>Medium</td></tr>
                            <tr><td>Network</td><td>ETH, BTC</td></tr>
                            <tr><td>Grid Size</td><td>Auto-grow</td></tr>
                            <tr><td>Refresh rate</td><td>hourly </td></tr>
                            <tr><td>Pulse rate</td><td>Normal</td></tr>
                            <tr><td>Energy efficiency</td><td>Efficient</td></tr>
                            <tr><td>Hope Index</td><td>Overwhelmed</td></tr>
                            <tr><td>Trust Pulse</td><td>Wary</td></tr>
                            <tr><td>Community Spirit</td><td>Spirited</td></tr>

                        </tbody>
                    </table>
                </div>

            </section>


            <section className="App-how-it-works">
                <h3>The Mechanics</h3>
                <p><strong>Step 1</strong> </p>
                <p>A polling service that continuously monitors the public BTC and ETH networks. It captures the gas spend of the last transaction  and store the data.</p>

                <p><strong>Step 2</strong></p><p>A visual generation service that reads gas spend records for the past 30 days (720 hours). It calculates the current pulse by comparing the value in the grid against the 30-day baseline (You can change the baseline and view the difference).
                </p>



                <p><strong>Step 3</strong> </p><p>The average of the BTC and ETH gas variations determines the color of a new heart. If both are primarily green, the heart will also be green, else a variation of red is assigned based on the threshold. Each new heart is dynamically added to the canvas grid, colored according to the derived metrics. Colors are assigned based on the thresholds (5%, 15%, 25%).</p>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <div style={{ color: '#d9381e', size: 0.8 }} >AGONY ⟶ </div> {/* Text after the green colors */}

                    {redColors.slice().reverse().map(color => (
                        <ColorBlock key={color} color={color} />
                    ))}
                    {greenColors.map(color => (
                        <ColorBlock key={color} color={color} />
                    ))}
                    <div style={{ color: '#18bd22', size: 0.8 }}> ⟵ LOVE</div> {/* Text after the green colors */}

                </div>

                <p><strong>Step 4</strong></p><p>A grid of heatrbeats is generated realtime on the canvas that displays hearts, each representing data points.
                </p>
                <p><strong>Step 5</strong></p><p>Mints the snapshot of grids to public blockchain periodically.
                    <a class="prj" href="https://ipfs.io/ipfs/Qmd4G9k9FUE9EHux9RnZuDrxaMSxPWpVxLZWBDLPGH8VSj" target="_blank">View the dynamic NFT metadata</a> in the public blockchain</p>


            </section>



            <section className="App-about-contact">
                <h3>About</h3>
                <p>The Heart Beat is a project created as an extension of <a class="prj" href="https://trust.uncode.art" target="_blank">The Alchemy of Trust</a> initiative, primarily to augment the idea of how future of money is evolving thus value is evolving. The project is created conceptualized by <a class="prj" href="https://uncode.art" target="_blank">Afzal Ibrahim</a>, an avid explorer of contemporary design, functional aesthetics and emerging tech. The decentralized world is constantly evolving and the piece is a symbolic representation of how the future of value is evolving through continuous heartbeats. View more projects at <a class="prj" href="https://uncode.art" target="_blank">uncode.art</a>
                </p>
                <h3>Contact</h3>
                <p>Email: ai[@]uncode.art</p>
            </section>
            <div class="divider"></div>
            <section className="App-faq">
                <h3>FAQ</h3>
                <div className="faq-item">
                    <h4>What is "The Heatbeat" artwork about?</h4>
                    <p>The work explores how the concept of trust is evolving in our increasingly digital world. This also means the economics of value (money and commodity) is also evolving. The artwork uses dynamic elements to visually represent the ongoing changes in the digital economy.
                    </p>
                </div>
                <div className="faq-item">
                    <h4>Why was the piece created?
                    </h4>
                    <p>The future is decentralized. The piece is a way for viewers to reflect on and engage with the idea of trust in a digital era. A detailed analysis of this topic can be found in the parent project - The Alchemy of Trust</p>
                </div>
                <div className="faq-item">
                    <h4> What is decentralization?</h4>
                    <p>Decentralization refers to the distribution of power away from a central authority. In terms of technology, it means managing a network of information in an automous way. Each participant engaging in the network has an equal role. The technology used to achieve this is called blockchain.
                    </p>
                </div>
                <div className="faq-item">
                    <h4>What is a dynamic NFT? </h4>
                    <p>A dynamic NFT is a type of digital artwork that changes over time based on external data or interactions. In this case, the NFT evolves based on real-time market data.
                    </p>
                </div>
                <div className="faq-item">
                    <h4>How does the artwork change?
                    </h4>
                    <p>The artwork evolves every one hour.  It changes by tracking and visualizing the 'pulse' or activity within cryptocurrency markets. The canvas is dynamically generated using software code. Changes in market conditions alter the colors and rhythms displayed in the artwork.
                    </p>
                </div>
                <div className="faq-item">
                    <h4> What does the color signify in this artwork?
                    </h4>
                    <p>In "The Heart Beat," green represents love and positivity, aligning with periods of market growth or stability. This is an ideal world, however a red canvas or a hybrid one isn't a bad one. After all, that's how markets are : - )
                    </p>
                </div>
                <div className="faq-item">
                    <h4>How does this artwork relate to the future of trust?
                    </h4>
                    <p>The artwork reflects on how trust is built and maintained in decentralized systems. The concept suggests that trust in the future will be based on transparency and shared control rather than centralized authority.
                    </p>
                </div>
                <div className="faq-item">
                    <h4>Can the viewers interact with the artwork?
                    </h4>
                    <p>Yes. Each heart beat color is arrived based on calculating the current v/s a baseline of x days. Viewers can interact by exploring different time frames and details of the market's behavior, which affects the visual representation in real time.

                    </p>
                </div>
                <div className="faq-item">
                    <h4> What do viewers will take away from this artwork?

                    </h4>
                    <p>The future of trust is zero trust. It means trust will operate autonomously in the digital world. The artwork isn't about providing a 360 view of the future but providing an alternative perspective on the relevance of a decentralized future and considering how values like trust and transparency can evolve in such a landscape.
                    </p>
                </div>            </section>
                <section className="App-about-contact">
                
 <p>© uncode.art</p>
               
            </section>
        </div>
    );
}

export default ViewHomeComponent;
