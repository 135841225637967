import axios from 'axios';

const API_URL = 'https://uncode-heartbeat-service.onrender.com';
const defaultLimitRecords = 0;

export const getFees = async (limit) => {
    const limitRecords = limit ? limit : defaultLimitRecords;

    try {
        const response = await axios.get(`${API_URL}/fees`, {
            params: {
                limit: limitRecords
            }
        });
        const allRecords = response.data;

        console.log(limitRecords);

        // Sort records by date and time in descending order
        const sortedRecords = allRecords.sort((a, b) => {
            const dateA = new Date(`${a.date}T${a.hour}`);
            const dateB = new Date(`${b.date}T${b.hour}`);
            return dateB - dateA;
        });

        // Process records to include baseline and variation calculations
        const processedRecords = await Promise.all(sortedRecords.map((record, index, array) => {
            // Get the previous records up to the current index, with a maximum of limitRecords
            const previousRecords = array.slice(Math.max(index - limitRecords, 0), index);

            // Calculate the baselines for BTC and ETH gas fees
            const btcBaseline = previousRecords.length > 0 
                ? (previousRecords.reduce((acc, curr) => acc + curr.BTCGasFee, 0) / previousRecords.length).toFixed(2) 
                : record.BTCGasFee.toFixed(2);
                
            const ethBaseline = previousRecords.length > 0 
                ? (previousRecords.reduce((acc, curr) => acc + curr.ETHGasFee, 0) / previousRecords.length).toFixed(2) 
                : record.ETHGasFee.toFixed(2);

            // Calculate the variations for BTC and ETH gas fees
            const btcVariation = (record.BTCGasFee / parseFloat(btcBaseline)).toFixed(2);
            const ethVariation = (record.ETHGasFee / parseFloat(ethBaseline)).toFixed(2);

            // Determine the pulses based on the variations
            const btcPulse = Math.abs(btcVariation - 1) >= 0.15 ? 1 : 0;
            const ethPulse = Math.abs(ethVariation - 1) >= 0.15 ? 1 : 0;
            const overallPulse = (btcPulse === 1 && ethPulse === 1) ? 1 : 0;

            return {
                date: record.date,
                hour: record.hour,
                BTCGasFee: record.BTCGasFee,
                ETHGasFee: record.ETHGasFee,
                btcBaseline: parseFloat(btcBaseline),
                ethBaseline: parseFloat(ethBaseline),
                BTCPulse: btcPulse,
                ETHPulse: ethPulse,
                OverallPulse: overallPulse,
                BTCVariation: parseFloat(btcVariation),
                ETHVariation: parseFloat(ethVariation)
            };
        }));

        return processedRecords;
    } catch (error) {
        console.error('Error fetching gas fees:', error);
        throw error;
    }
};
